import { Button } from "@chakra-ui/button";
import { CSSProperties, useState } from "react";
import { ButtonProps, Flex, Image, Text } from "@chakra-ui/react";

type btnTypes = "priLg" | "priMd" | "secLg" | "secMd" | "chl" | "";
interface Props extends ButtonProps {
  handleSubmit: () => void;
  isLoading?: boolean;
  title: string;
  svg?: string;
  fontSize?: string | string[];
  ff?: string; //fontFamily
  fontWeight?: string;
  isDisabled?: boolean;
  style?: CSSProperties;
  miniTxt?: string;
  prImg?: string;
  btnTypes?: btnTypes;
  w?: string;
  isLoadingDots?: boolean;
}

const lg: ButtonProps = { py: [".75rem", "1rem"], px: "2rem", fontSize: ["xl", "2xl"] };
const medium: ButtonProps = { py: ".5rem", pl: "1.5rem", pr: ".5rem", fontSize: "md" };
const pri: ButtonProps = {
  bgColor: "#CBF356",
  borderEndRadius: "1.5rem",
  borderBottomLeftRadius: "1.5rem",
  /*_hover: { background: "#646464" },*/ _active: { background: "#CBF356" },
  color: "#000",
};
const sec: ButtonProps = { bgColor: "#fff", _hover: { background: "#000", color: "#fff" }, _active: { background: "#000", color: "#fff" } };

export const CustomButton = (props: Props) => {
  const { handleSubmit, isLoading, title, svg, fontWeight, btnTypes, w, ...btnProps } = props;
  const { isDisabled, style, miniTxt, prImg } = props;
  const [isClicked, setIsClicked] = useState(false);

  const challenge: ButtonProps = {
    py: ".5rem",
    px: "1rem",
    fontSize: "sm",
    bgColor: "#fff",
    color: "#000",
    border: "2px solid black",
    _hover: { background: "#646464", color: "#fff" },
    _focus: { background: "#000", color: "#fff" },
  };

  const priLg: ButtonProps = { ...pri, ...lg };
  const priMd: ButtonProps = { ...pri, ...medium };
  const secLg: ButtonProps = { ...sec, ...lg };
  const secMd: ButtonProps = { ...sec, ...medium };

  let btnStyle: ButtonProps = {};
  if (btnTypes) {
    btnTypes === "priMd"
      ? (btnStyle = priMd)
      : btnTypes === "secLg"
      ? (btnStyle = secLg)
      : btnTypes === "secMd"
      ? (btnStyle = secMd)
      : (btnStyle = challenge);
  } else btnStyle = priLg;

  const handleButtonClick = async () => {
    setIsClicked(!isClicked);
    handleSubmit();

    const reset = () => {
      return new Promise<void>(resolve => {
        const sT = setTimeout(() => {
          setIsClicked(false);
          resolve();
          clearTimeout(sT);
        }, 500);
      });
    };
    await reset();
  };

  return (
    <Button
      {...btnStyle}
      style={style}
      w={w || "auto"}
      variant="solid"
      mx="auto"
      isDisabled={isDisabled || false}
      onClick={handleButtonClick}
      isLoading={isLoading ? true : false}
      fontWeight={fontWeight || "600"}
      cursor={isLoading ? "not-allowed" : "pointer"}
      pointerEvents={isDisabled ? "none" : "auto"}
      {...btnProps}
    >
      <Flex align="center" justify="center">
        {title}
        {svg ? <Image src={svg} alt={svg} pr={prImg || ".625rem"} pl={".625rem"} /> : undefined}

        {miniTxt && (
          <Text fontSize="xs" pl="0.625rem">
            {miniTxt}
          </Text>
        )}
      </Flex>
    </Button>
  );
};
