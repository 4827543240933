export const clickHandler = (txt: string) => {
  const id = txt.trim().split(" ")?.pop()?.toLowerCase() as string;

  const solutionsElement = document.getElementById(id);
  if (solutionsElement) {
    solutionsElement.scrollIntoView({ behavior: "smooth" });
  }
};

export const isMobile = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
