import { Button, Flex, Image, Img, Modal, ModalBody, ModalContent, Text, useDisclosure } from "@chakra-ui/react";

import { useRef } from "react";
import { BlueGreenLightningIcon } from "../../icons";
import { clickHandler } from "../../utils";
import { CustomButton } from "../button";
import { Overlay } from "../ui/overlay";
import { HeaderProps } from "../header";

export const MobileNav = ({color}: HeaderProps) => {
  const finalRef = useRef(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex w="full" pos={"fixed"} zIndex={1000} display={["flex", "flex", "flex", "none", "none"]}>
      <Flex w="full" align={"center"} justify={"space-between"} p={".75rem"}>
        <Flex align={"center"} columnGap={".5rem"}>
          <BlueGreenLightningIcon style={{ width: "30px", height: "30px" }} />
          <Text fontSize={"2.5rem"} fontWeight={"800"} color={color}>
            flash
          </Text>
        </Flex>

        <Img src="svg/menu-icon.svg" onClick={() => onOpen()} />
      </Flex>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} isCentered={false} onClose={onClose}>
        <Overlay isOpen={isOpen} Zindex={100} />
        <ModalContent
          mt={0}
          mx="0.8rem"
          borderRadius={"1.5rem .125rem 1.5rem 1.5rem"}
          bgColor={"#fff"}
          border="0.0625rem solid black"
          zIndex={2000}
          ml={"auto"}
        >
          <Flex w="full" justify={"flex-end"} mb={"2rem"} mt={"1rem"}>
            <Button border={"none !important"} outline={"none !important"} bg="transparent" color="#000000" zIndex={2001} onClick={() => onClose()}>
              <Image src="svg/close.svg" alt="close" />
            </Button>
          </Flex>
          <ModalBody w="full" pos={"relative"} display="flex" flexDirection="column" rowGap={"2rem"}>
            {["The solution", "How it works", "Benefits", "Pricing"].map((txt, i) => (
              <Flex
                color={"#343231"}
                fontSize={"1rem"}
                fontWeight={"600"}
                lineHeight={"16px"}
                w="full"
                justify={"flex-end"}
                p=".125rem 0rem .125rem 0rem"
              >
                <Text
                  onClick={() => {
                    onClose();
                    clickHandler(txt);
                  }}
                >
                  {txt}
                </Text>
              </Flex>
            ))}
            <Flex justify={"flex-end"} mb={"1rem"}>
              <CustomButton
                handleSubmit={() => {
                  onClose();
                  clickHandler("contact");
                }}
                title="Try out"
                btnTypes="priMd"
                m={"0rem !important"}
                svg="svg/hero/arrow-right_circle.svg"
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
