import { Box, Flex } from "@chakra-ui/react";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useState } from "react";
import "./App.css";
import { BenefitsSection } from "./components/benefits-section";
import { ContactUs } from "./components/contact";
import { Header } from "./components/header";
import { Hero } from "./components/hero";
import { MobileNav } from "./components/mobile-nav";
import { Pricing } from "./components/pricing";
import { ProblemSectionOne } from "./components/problem-section-one";
import { ProblemSectionTwo } from "./components/problem-section-two";
import { Solutions } from "./components/solutions";
import { TextSlider } from "./components/text-slider";
import { useMediaQuery } from "react-responsive";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const [headerColor, setHeaderColor] = useState<string>("#fff");
  const refOne = useRef<HTMLDivElement | null>(null);
  const refTwo = useRef<HTMLDivElement | null>(null);
  const refThree = useRef<HTMLDivElement>(null);
  const textSliderRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: "(max-width: 920px)" });
  const sectionsRef = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      let mm = gsap.matchMedia();
      mm.add("(min-width: 920px)", () => {
        const tl = gsap.timeline();
        const sectionOne = document.querySelector(".section-1") as HTMLDivElement;
        const sectionTwo = document.querySelector(".section-2") as HTMLDivElement;

        if (sectionOne && sectionTwo) {
          tl.from(sectionTwo, { opacity: 0, y: "100%", duration: 0.25 }, "-=0.5");

          ScrollTrigger.create({
            trigger: sectionOne,
            start: "top top",
            end: () => `+=${sectionOne.offsetHeight}`,
            pinSpacing: false,
            toggleActions: "restart none none none",
            pin: true,
            snap: 1,
            onEnter: () => {
              tl.play();
            },

            onLeaveBack: () => {
              tl.reverse();
            },
          });
        }
        return () => {
          tl.kill();
        };
      });

      return () => {
        mm.kill();
      };
    },
    { scope: sectionsRef }
  );

  useGSAP(
    () => {
      ScrollTrigger.create({
        trigger: sectionsRef.current,
        start: `top-=${isMobile ? 50 : 100} top`,
        end: `bottom top`,
        onEnter: () => setHeaderColor("#cbc9c9"),
        onLeave: () => setHeaderColor("#fff"),
        onLeaveBack: () => setHeaderColor("#fff"),
        onEnterBack: () => setHeaderColor("#cbc9c9"),
      });
    },
    { scope: sectionsRef }
  );
  useGSAP(
    () => {
      ScrollTrigger.create({
        trigger: refThree.current,
        start: `top-=${isMobile ? 50 : 100} top`,
        end: "bottom top",
        onEnter: () => setHeaderColor("#cbc9c9"),
        onLeave: () => setHeaderColor("#fff"),
        onLeaveBack: () => setHeaderColor("#fff"),
        onEnterBack: () => setHeaderColor("#cbc9c9"),
      });
    },
    { scope: refThree }
  );
  useGSAP(
    () => {
      ScrollTrigger.create({
        trigger: refOne.current,
        onEnter: () => setHeaderColor("#fff"),
      });
    },
    { scope: refOne }
  );
  useGSAP(
    () => {
      ScrollTrigger.create({
        trigger: textSliderRef.current,
        start: `top-=${isMobile ? 50 : 100} top`,
        end: `bottom+=${isMobile ? 50 : 100} top`,
        onEnter: () => setHeaderColor("#fff"),
        onEnterBack: () => setHeaderColor("#fff"),
      });
    },
    { scope: textSliderRef }
  );

  return (
    <Flex w="full">
      <Box w="full" bgColor={"#FAFAFA"}>
        <Header color={headerColor} />
        <Box ref={refOne} w="full">
          <MobileNav color={headerColor} />
          <Hero />
        </Box>
        <Box ref={sectionsRef} w="full">
          <ProblemSectionOne />
          <ProblemSectionTwo />
        </Box>
        <Box ref={textSliderRef} w="full">
          <TextSlider />
        </Box>
        <Solutions solutionRef={refTwo} />
        <Box w="full" ref={refThree}>
          <BenefitsSection />
          <Pricing />
        </Box>
        <ContactUs />
      </Box>
    </Flex>
  );
};

export default App;
