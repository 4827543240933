import { Box, Flex, Text } from "@chakra-ui/react";

export const ProblemSectionTwo = () => {
  return (
    <Box w="full" h={["50vh", "50vh", "50vh", "100vh", "100vh"]}>
      <Box className="section-2" w="full" h="full" bgColor={"#fff"} pos="relative" zIndex={20}>
        <Flex
          pos={"relative"}
          zIndex={15}
          w="full"
          h="full"
          align={"center"}
          flexDir={"column"}
          justify={"center"}
          p={["5rem 2rem 0rem 1rem", "5rem 2rem 0rem 1rem", "0rem 5rem 0rem 5rem"]}
        >
          <Flex align={"center"} flexDir={"column"} rowGap={"3.5rem"}>
            <Flex rowGap={"1.4rem"} flexDir={"column"}>
              <Flex
                flexDir={["column", "column", "row", "row"]}
                align={"center"}
                justify={"center"}
                fontWeight={"800"}
                fontSize={["2rem", "2.5rem", "3rem", "3.5rem"]}
                lineHeight={["39.01px", "39.01px", "56px", "88px"]}
              >
                <Text textAlign={"center"} color={"#343231"}>
                  No more
                </Text>
                <Box
                  bgColor={"#B176FE"}
                  p={[".5rem 1rem .5rem 1rem", ".5rem 1rem .5rem 1rem", "0.75rem 1rem", "0.75rem 1rem"]}
                  ml={".625rem"}
                  borderRadius={"1.25rem 1.25rem 1.25rem 0.125rem"}
                >
                  <Text color="#fff">No-Shows</Text>
                </Box>
              </Flex>

              <Text
                fontSize={["1rem", "1rem", "1.5rem", "2rem"]}
                lineHeight={["19.5px", "19.5px", "29.5px", "39px"]}
                color={"#343231"}
                textAlign={"center"}
                whiteSpace={["pre-wrap", "pre-wrap", "pre-wrap", "pre-wrap", "nowrap"]}
              >
                Discover a modern solution designed for the dynamic hiring demands <Box as="br" display={["none", "none", "inline-block"]} /> across
                fast-paced industries.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          pos="absolute"
          zIndex={10}
          top={["-5rem", "-5rem", "0rem", "-8rem"]}
          right={0}
          width={["120%", "120%", "full", "full", "full"]}
          height={["120%", "120%", "85%", "85%", "85%"]}
          bgImage="svg/section-two/white-shape.svg"
          bgSize="contain"
          bgRepeat="no-repeat"
          bgPos={["center", "center", "right", "right"]}
          bottom={"40%"}
        />
      </Box>
    </Box>
  );
};
