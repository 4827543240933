import { keyframes } from "@emotion/react";
import { Box, BoxProps, Flex, Img, Text, useDisclosure, ScaleFade } from "@chakra-ui/react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from "react";
import { ArrowRight, BlueChatIcon, BlueHeadSetIcon, BubbleChatIcon, LineIcon } from "../../icons";

import { useMediaQuery } from "react-responsive";
import SwiperCore from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import { useInView } from "react-intersection-observer";

const scaleUpThenShrink = keyframes`
0% {
  transform: scale(0.5);
  opacity: 0;
}
70% {
  transform: scale(1.03);
    opacity: 0.5;
}
100% {
  transform: scale(1);
    opacity: 1;
}
`;
type props = {
  solutionRef: MutableRefObject<HTMLDivElement | null>;
};
export const Solutions = ({ solutionRef }: props) => {
  const [isCardHovered, setIsCardHovered] = useState(false);

  const [indexes, setIndexes] = useState<number[]>([]);

  const isMobile = useMediaQuery({ query: "(max-width: 920px)" });

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const howCardData = [
    {
      title: "Add Flash Interview to <br/>Your Job Postings",
      body: (
        <Box as="span">
          Integrate with a simple link or
          <Box as="br" display={["inline-block", "inline-block", "none"]} /> button.
        </Box>
      ),
      bgColor: "#CBF356",
    },
    {
      title: "Candidates Qualify",
      body: (
        <Box as="span">
          Applicants complete a quick form <Box as="br" display={["inline-block", "inline-block", "none"]} />
          to express interests.
        </Box>
      ),
      bgColor: "#B176FE",
    },
    {
      title: "Review submissions",
      body: (
        <Box as="span">
          Qualified candidates are instantly <Box as="br" display={["inline-block", "inline-block", "none"]} /> connected for interviews.
        </Box>
      ),
      bgColor: "#fff",
    },
    {
      title: "Analyze and export data",
      body: (
        <Box as="span">
          Effortlessly analyze applicants <Box as="br" display={["inline-block", "inline-block", "none"]} />
          insights and export data for <Box as="br" display={["inline-block", "inline-block", "none"]} /> comprehensive review.
        </Box>
      ),
      bgColor: "#FE67D7",
    },
  ];

  const { onOpen, onClose } = useDisclosure();

  const { ref, inView } = useInView({ triggerOnce: false });

  useEffect(() => {
    if (inView) onOpen();
  }, [inView, onOpen, onClose]);

  const handleAnimationEnd = () => {
    onClose();
  };

  return (
    <Box
      ref={el => {
        if (el) {
          ref(el);
        }
      }}
      w="full"
      background="linear-gradient(104.35deg, #BE82FF 0%, #7E14F7 49.25%)"
      position="relative"
    >
      <Box
        position="absolute"
        bgImage={"url(images/solutions-bg.jpg)"}
        bgSize={"cover"}
        bgPos={"center"}
        bgRepeat={"no-repeat"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        transform={"rotate(-180 deg)"}
        opacity={0.15}
        zIndex={1}
        w="full"
        h={"full"}
      />
      {isMobile ? (
        <SolutionsMobileSection />
      ) : (
        <Flex ref={solutionRef} id="solution" className="panel" pos={"relative"} pb="2rem" w="full" minH="100vh" zIndex={2}>
          <Flex w="full" maxH={"full"} minH={["auto", "auto", "auto"]} align={"center"} justify={"center"}>
            <Flex align={"flex-end"} flexDir={"column"} flexGrow={1} h={"full"} pos={["static", "static", "relative"]}>
              <div onAnimationEnd={handleAnimationEnd}>
                <Card
                  body="Eliminate no-shows with real-time, <br/> engaging interviews."
                  borderRadius={["1rem 1rem .125rem 1rem"]}
                  mt={["8rem", "10rem", "12rem"]}
                  mr={["1rem", "1rem", "3rem"]}
                  ml={["1rem", "1rem", "1rem"]}
                  title="Instant Connections"
                  Icon={<BubbleChatIcon style={{ width: "80px", height: "70px" }} />}
                  css={{
                    animation: inView ? `${scaleUpThenShrink} 3s ease-in-out` : "none",
                  }}
                />
              </div>
            </Flex>
            <Flex
              borderRadius={"2.5rem"}
              w={["full", "full", "full", "520px"]}
              h={["full", "full", "full", "840px"]}
              bgImage={"url(images/main-interview.gif)"}
              bgSize={"cover"}
              bgPos={"center"}
              bgRepeat={"no-repeat"}
              pos={"relative"}
              mt="1rem"
            >
              <Flex
                flexDir={"column"}
                zIndex={20}
                pos={"absolute"}
                bottom={["0rem", "0rem", "0rem", "0rem", "0rem", "20%"]}
                color={"#fff"}
                right={["0", "0", "0", "50%", "50%", "50%", "50%"]}
              >
                <Text
                  ml={["1rem", "1rem", "1rem", "-2rem", "-4rem", "-5rem"]}
                  fontWeight={"800"}
                  whiteSpace={"nowrap"}
                  lineHeight={"107px"}
                  fontSize={["2rem", "2.5rem", "3rem", "3.5rem", "4rem", "4.5rem", "5rem", "5.5rem"]}
                >
                  Bringing back
                </Text>
                <Box ml={["2%", "4%", "6%", "8%", "10%", "12%", "14%"]}>
                  <Text
                    fontWeight={"800"}
                    whiteSpace={"nowrap"}
                    lineHeight={"107px"}
                    fontSize={["2rem", "2.5rem", "3rem", "3.5rem", "4rem", "4.5rem", "5rem", "5.5rem"]}
                    pos="relative"
                  >
                    <Box as="span" pos="relative" zIndex={1}>
                      <Box as="span" pos="relative" zIndex={10}>
                        Doorstep Dialog
                      </Box>
                      <ScaleFade in={inView} transition={{ enter: { duration: 3, ease: "easeInOut" } }}>
                        <Box
                          as="span"
                          pos={"absolute"}
                          bottom={[".5rem", ".6rem", ".8rem", "0rem"]}
                          left={0}
                          w={["full", "50%", "full"]}
                          h={[".5rem", ".6rem", ".8rem", "1rem"]}
                          bgColor={"#FE67D7"}
                          transition={"all 0.4s ease-out"}
                        />
                      </ScaleFade>
                    </Box>
                  </Text>
                  <Text
                    fontWeight={"500"}
                    fontSize={"1.75rem"}
                    lineHeight={"34.13px"}
                    whiteSpace={["wrap", "wrap", "wrap", "wrap", "wrap", "nowrap", "nowrap", "nowrap"]}
                  >
                    Flash Interviews recreate the direct engagement of <Box as="br" /> applicants showing up at your doorstep, facilitated
                    <Box as="br" /> through digital means.
                  </Text>
                </Box>
              </Flex>
              <Box pos={"absolute"} bottom={"-1.5rem"} left={"-3rem"}>
                <ScaleFade in={inView} transition={{ enter: { duration: 4, ease: "easeIn" } }}>
                  <LineIcon />
                </ScaleFade>
              </Box>
            </Flex>
            <Flex
              align={"flex-start"}
              justify={"space-between"}
              flexDir={"column"}
              h={"full"}
              flexGrow={1}
              pos={["static", "static", "relative"]}
              zIndex={10}
            >
              <Flex justify={"center"} align={"center"} flexGrow={1} columnGap={".75rem"} mt={["2rem"]} ml={"1rem"}>
                <ScaleFade in={inView} transition={{ enter: { duration: 3, ease: "easeIn" } }}>
                  <BlueHeadSetIcon style={{ width: "48px", height: "48px" }} />
                </ScaleFade>
                <ScaleFade in={inView} transition={{ enter: { duration: 3, ease: "easeIn" } }}>
                  <BlueChatIcon style={{ width: "56px", height: "57px" }} />
                </ScaleFade>
              </Flex>
              <Flex flexGrow={1} w={["full", "full", "full", "auto", "auto", "auto"]}>
                <Box w="full">
                  <Card
                    title="Simple Integration"
                    body="Easily add Flash Interviews to your <br/> existing job postings."
                    borderRadius={"1rem 1rem 1rem 0.125rem"}
                    ml={["1rem", "1rem", "-3rem"]}
                    Icon={<Img src="images/intergrate.png" width={["40px", "50px", "80px"]} height={["40px", "50px", "70px"]} />}
                    w={["full"]}
                    p={["1rem 8.5rem 1.5rem .5rem"]}
                    css={{
                      animation: inView ? `${scaleUpThenShrink} 3s ease-in-out` : "none",
                    }}
                  />
                </Box>
              </Flex>
              <Flex flexGrow={1} w={["full", "full", "full", "auto", "auto", "auto"]}>
                <Box w="full">
                  <ImageCard
                    title="Efficient Management"
                    body="An admin side for recruiters  <br/> to manage applicants and  <br/> data effortlessly."
                    borderRadius={".125rem 1rem 1rem 1rem"}
                    ml={["1rem", "1rem", "-1rem"]}
                    mb={"-3rem"}
                    mr={["1rem", "1rem", "1rem"]}
                    w={["full"]}
                    css={{
                      animation: inView ? `${scaleUpThenShrink} 3s ease-in-out` : "none",
                    }}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}

      <Flex id="works" flexDir={"column"} pos="relative" w="full">
        <Text
          w="full"
          pos={"absolute"}
          top={[40, 20, 20, 0]}
          mt={"-8rem"}
          opacity={[0.8, 0.8, 0.8, 1]}
          color={["#5E1BF2", "#5E1BF2", "#7E14F7"]}
          fontWeight={"800"}
          fontSize={["7rem", "7rem", "7rem", "8rem", "10rem", "14rem", "16.25rem"]}
          textAlign={["left", "left", "center"]}
          lineHeight={["100px", "130px", "160px", "190px", "220px", "250px", "341px"]}
          whiteSpace={["nowrap"]}
          zIndex={1}
        >
          How it <Box display={["inline-block", "inline-block", "none"]} as="br" /> works
        </Text>

        <Box marginBottom={["6rem", "7.5rem", "7.5rem", "0rem"]} marginTop={["6.95rem", "6.95rem", "5.5rem", "0rem"]}>
          {isMobile ? (
            <Box>
              <Swiper
                grabCursor={true}
                slidesPerView={1.2}
                onActiveIndexChange={(i: SwiperCore) => {
                  setActiveSlideIndex(i.activeIndex);
                }}
              >
                {howCardData.map((data, index) => (
                  <SwiperSlide key={index}>
                    <HowCard
                      body={data.body}
                      index={index + 1}
                      ml={index === 0 ? "1rem" : "0rem"}
                      maxWidth={index === 0 ? "calc(100% - 1rem) !important" : "full"}
                      title={data.title}
                      bgColor={data.bgColor}
                      borderRadius={".125rem 1.5rem 1.5rem 1.5rem"}
                      setIsCardHovered={setIsCardHovered}
                      isCardHovered={isCardHovered}
                      setIndexes={setIndexes}
                      indexes={indexes}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Flex justify={"center"} columnGap={"1rem"} pt="2rem">
                {[1, 2, 3, 4].map((val, i) => {
                  return <Box className={activeSlideIndex === i ? "swiper-pagination-bullet-active" : "swiper-pagination-bullet"}></Box>;
                })}
              </Flex>
            </Box>
          ) : (
            <Flex pos="relative" zIndex={20} py="5rem" px="2rem" justify={"center"} w="full">
              {howCardData.map((data, index) => (
                <HowCard
                  key={index}
                  body={data.body}
                  index={index + 1}
                  title={data.title}
                  bgColor={data.bgColor}
                  borderRadius={".125rem 1.5rem 1.5rem 1.5rem"}
                  setIsCardHovered={setIsCardHovered}
                  isCardHovered={isCardHovered}
                  setIndexes={setIndexes}
                  indexes={indexes}
                />
              ))}
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

interface CardProps extends BoxProps {
  title: string;
  body: string;
  Icon: ReactNode;
}
const Card = ({ body, title, Icon, ...props }: CardProps) => {
  return (
    <Box
      w={["88vw", "88vw", "88vw", "83%"]}
      bgColor={"#fff"}
      p={["1rem 1rem 1rem 1rem", "1rem 1rem 1rem 1rem", "1.5rem 1.5rem 2rem 1.5rem"]}
      {...props}
    >
      <Flex align={"center"}>
        {Icon}
        <Text
          fontWeight={"700"}
          ml={".5rem"}
          color={"#343231"}
          fontSize={["1.25rem", "1.25rem", "2xl"]}
          lineHeight={"40px"}
          whiteSpace={["wrap", "wrap", "wrap", "nowrap", "nowrap", "nowrap", "nowrap"]}
        >
          {title}
        </Text>
      </Flex>
      <Text
        fontWeight={"400"}
        fontSize={["1rem", "1rem", "xl"]}
        lineHeight={["19.5px", "19.5px", "24px"]}
        color={"#343231"}
        dangerouslySetInnerHTML={{ __html: body }}
        whiteSpace={["wrap", "wrap", "wrap", "nowrap", "nowrap", "nowrap", "nowrap"]}
      ></Text>
    </Box>
  );
};
interface ImageCardProps extends BoxProps {
  title: string;
  body: string;
}
const ImageCard = ({ body, title, ...props }: ImageCardProps) => {
  return (
    <Flex bgColor={"#fff"} p={["1.5rem 1rem 1.5rem .75rem", "1.5rem 1rem 1.5rem .75rem", "1rem 3rem 1.5rem 1rem"]} align={"center"} {...props}>
      <Img
        w={["77px", "80px", "125px"]}
        h={["92px", "92px", "169px"]}
        src="images/typing.gif"
        borderRadius={[".125rem .75rem .75rem .75rem", ".125rem .75rem .75rem .75rem", ".75rem", ".75rem", "1.5rem"]}
      />
      <Box px={"1rem"}>
        <Text
          fontWeight={"700"}
          ml={["0", "0", "0"]}
          color={"#343231"}
          fontSize={["1.25rem", "1.25rem", "1.25rem", "1.25rem", "2xl"]}
          lineHeight={["24px", "24px", "40px"]}
          whiteSpace={["wrap", "wrap", "wrap", "nowrap", "nowrap", "nowrap", "nowrap"]}
          // pl={["1.5rem"]}
        >
          {title}
        </Text>
        <Text
          fontWeight={"400"}
          whiteSpace={["wrap", "wrap", "wrap", "nowrap", "nowrap", "nowrap", "nowrap"]}
          fontSize={["1rem", "1rem", "xl"]}
          color={"#343231"}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </Box>
    </Flex>
  );
};

interface HowCardCardProps extends BoxProps {
  index: number;
  title: string;
  body: ReactNode;
  setIsCardHovered: React.Dispatch<React.SetStateAction<boolean>>;
  isCardHovered: boolean;
  setIndexes: React.Dispatch<React.SetStateAction<number[]>>;
  indexes: number[];
}

const HowCard = ({ index, body, title, isCardHovered, setIsCardHovered, indexes, setIndexes, ...props }: HowCardCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const [hovered, setHovered] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 920px)" });
  const tl = gsap.timeline();

  useEffect(() => {
    if (hovered) {
      setIsCardHovered(true);
    } else setIsCardHovered(false);
  }, [hovered]);

  useEffect(() => {
    const cardEl = cardRef.current;
    if (isCardHovered && index === 1 && !hovered) {
      handleMouseLeave();
    }
  }, [hovered, index, isCardHovered]);

  useEffect(() => {
    if (indexes.length === 0 && index === 1) {
      tl.to(cardRef.current, { width: "100%", duration: 1 }).fromTo(
        textRef.current,
        { opacity: 0, scale: 0 },
        { opacity: 1, width: "100%", scale: 1, duration: 0.0, ease: "elastic.in" }
      );
    }

    return () => {};
  }, [indexes]);

  useGSAP(
    () => {
      const cardEl = cardRef.current;
      if (index !== 1 && !isMobile) tl.to(textRef.current, { opacity: 0, width: "0%", duration: 0.0 }).to(cardEl, { width: "50%", duration: 0.5 });
      cardEl?.addEventListener("mouseenter", handleMouseEnter);
      cardEl?.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        cardEl?.removeEventListener("mouseenter", handleMouseEnter);
        cardEl?.removeEventListener("mouseleave", handleMouseLeave);
      };
    },
    { scope: cardRef }
  );
  const handleMouseEnter = () => {
    if (isMobile) return;
    setHovered(true);

    setIndexes(prev => [...prev, index]);
    tl.to(cardRef.current, { width: "100%", duration: 0.5 }).fromTo(
      textRef.current,
      { opacity: 0, scale: 0 },
      { opacity: 1, width: "100%", scale: 1, duration: 0.0, ease: "elastic.in" }
    );
  };

  const handleMouseLeave = () => {
    if (isMobile) return;
    setIndexes(prev => [...prev, index].filter(i => i !== index));
    setHovered(false);

    tl.to(textRef.current, { opacity: 0, width: "0%", duration: 0.0 }).to(cardRef.current, { width: "50%", duration: 0.5 });
    // tl.reverse();
  };

  return (
    <Flex
      ref={cardRef}
      flexDir={"column"}
      color={"#343231"}
      w="full"
      p={[".5rem", ".5rem", ".5rem", ".5rem", ".52rem", ".75rem", "1rem"]}
      rowGap={"1rem"}
      width={["full", "full", "full"]}
      pos="relative"
      h={["264px", "350px", "360px", "380px", "395px", "380px", "390px", "293px"]}
      {...props}
    >
      <Flex
        align={"center"}
        margin={["1rem", "1rem", "1rem", "1rem"]}
        justify={"center"}
        bgColor={"#343231"}
        color={"#fff"}
        width={["48px", "48px", "48px", "56px"]}
        height={["48px", "48px", "48px", "56px"]}
        borderRadius={"50%"}
        fontSize={"2rem"}
        fontWeight={"800"}
      >
        {index}
      </Flex>
      <Text
        padding={["0rem 1rem 0rem 1rem", "0rem 1rem 0rem 1rem", "0rem 1rem 0rem 1rem"]}
        fontWeight={"700"}
        fontSize={["1.25rem", "1.25rem", "1.25rem", "1.25rem", "1.25rem", "1.25rem", "1.5rem"]}
        whiteSpace={"nowrap"}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <Text
        ref={textRef}
        padding={["0rem 1rem 1rem 1rem", "0rem 1rem 1rem 1rem", "0rem 1rem 1rem 1rem"]}
        fontSize={["1rem", "1rem", "1rem", "1.5rem"]}
        fontWeight={"400"}
        whiteSpace={["nowrap", "nowrap", "wrap"]}
        width={["full", "full", "full"]}
        // opacity={[1, 1, 1, largeScreenTextOpacity, largeScreenTextOpacity, largeScreenTextOpacity, largeScreenTextOpacity]}
      >
        {body}
      </Text>

      <Flex
        pos={"absolute"}
        bottom={["10px", "1.5rem", "1rem"]}
        right={"1rem"}
        flexGrow={1}
        justify={"flex-end"}
        align={"flex-end"}
        transform={[
          "none",
          "none",
          "none",
          (!hovered && !isCardHovered && index === 1 && indexes.length === 0) || hovered ? "rotate(0deg)" : "rotate(45deg)",
        ]}
        opacity={[1, 1, 1, (!hovered && !isCardHovered && index === 1 && indexes.length === 0) || hovered ? 1 : 0.5]}
        transition={"transform .4s ease-out, opacity .4s ease-out"}
      >
        <Box display={["none", "none", "inline-block"]}>
          <ArrowRight style={{ width: "56px", height: "56px" }} />
        </Box>
        <Box display={["inline-block", "inline-block", "none"]}>
          <ArrowRight style={{ width: "40px", height: "40px" }} />
        </Box>
      </Flex>
    </Flex>
  );
};

const SolutionsMobileSection = () => {
  const { onOpen, onClose } = useDisclosure();

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) onOpen();
  }, [inView, onOpen, onClose]);

  const handleAnimationEnd = () => {
    onClose();
  };
  return (
    <Flex ref={ref} id="solution" className="panel" pb="2rem" w="full" minH="100vh" pos="relative" zIndex={2}>
      <Flex w="full" maxH={"full"} minH={["full"]} direction={"column"}>
        <Flex maxW={"full"} pos={"relative"}>
          <div onAnimationEnd={handleAnimationEnd}>
            <Flex
              w={"full"}
              zIndex={8}
              pos={"absolute"}
              justify={"flex-end"}
              align={"center"}
              flexGrow={1}
              columnGap={".75rem"}
              mt={["3rem"]}
              pr={"1.5rem"}
            >
              <ScaleFade in={inView} transition={{ enter: { duration: 3, ease: "easeIn" } }}>
                <BlueHeadSetIcon style={{ width: "48px", height: "48px" }} />
                <BlueChatIcon style={{ width: "48px", height: "48px" }} />
              </ScaleFade>
            </Flex>
          </div>

          <Box w="full" pr={"4.25rem"} pos="relative" overflowX={"hidden"}>
            <Flex
              borderRadius={["0rem 1.5rem 1.5rem 0rem"]}
              w={["full", "full", "full", "520px"]}
              h={["65vh"]}
              bgImage={"url(images/main-interview.gif)"}
              bgSize={"cover"}
              bgPos={"center"}
              bgRepeat={"no-repeat"}
              mt="5rem"
              // flex={"0 0 75%"}
            />
            <Box zIndex={8} transform="rotate(25deg)" pos={"absolute"} top={"40%"} right={"-.80rem"}>
              <ScaleFade in={inView} transition={{ enter: { duration: 3, ease: "easeIn" } }}>
                <LineIcon style={{ width: "135px", height: "135px" }} />
              </ScaleFade>
            </Box>
          </Box>
        </Flex>
        <Flex
          flexDir={"column"}
          zIndex={20}
          bottom={["10rem"]}
          color={"#fff"}
          right={["0", "0", "0", "50%", "50%", "50%", "50%"]}
          mt={["-4.75rem", "-2.5rem", "-4rem"]}
          w={"100vw"}
          lineHeight={"2.75rem"}
          pt={["2px", "0rem"]}
        >
          <Text
            ml={["1rem"]}
            fontWeight={"800"}
            whiteSpace={"nowrap"}
            // lineHeight={"0.75rem"}
            fontSize={["2.5rem", "2.5rem", "3rem", "3.5rem", "4rem", "4.5rem", "5rem", "5.5rem"]}
            // w={"80%"}

            letterSpacing={"0.12rem"}
          >
            Bringing back
          </Text>

          <Box ml={["1rem", "0rem", "0rem", "0rem", "1rem"]}>
            <Text
              fontWeight={"800"}
              whiteSpace={"nowrap"}
              // lineHeight={"0.75rem"}
              fontSize={["2rem"]}
              pos="relative"
              // display="flex" //
              // justifyContent="space-between" //
            >
              <Box w={"100%"} textAlign={"justify"} pos="relative" zIndex={1}>
                <Box fontSize={"2.5rem"} pos="relative" zIndex={10} flexGrow={1} letterSpacing={"0.09rem"}>
                  Doorstep Dialog
                </Box>
                <ScaleFade in={inView} transition={{ enter: { duration: 3, ease: "easeIn" } }}>
                  <Box
                    as="span"
                    pos={"absolute"}
                    bottom={[".5rem", ".6rem", "0.1rem", "1rem"]}
                    left={2}
                    w={["85%", "45%"]}
                    h={[".5rem", ".6rem", ".8rem", "1rem"]}
                    bgColor={"#FE67D7"}
                  />
                </ScaleFade>
              </Box>
            </Text>
            <Text
              py={"1rem"}
              pr={"2rem"}
              fontWeight={"500"}
              fontSize={"1rem"}
              lineHeight={"19.5px"}
              whiteSpace={["wrap", "wrap", "wrap", "wrap", "wrap", "nowrap", "nowrap", "nowrap"]}
              letterSpacing={"0.02rem"}
            >
              Flash Interviews recreate the direct engagement of applicants showing up at your doorstep, facilitated through digital means.
            </Text>
          </Box>
        </Flex>
        <Flex maxW={"100vw"} align={"center"} justify={"center"} flexDir={"column"} h={"full"} flexGrow={1} pos={["static"]} zIndex={10}>
          <Flex flexGrow={1} pos={["static"]} w="full">
            <Box w="full" mx={"1rem"}>
              <Card
                body="Eliminate no-shows with real-time, engaging interviews."
                borderRadius={"0rem 1rem 1rem 1rem"}
                mt={["3rem"]}
                // m={["1rem"]}
                title="Instant Connections"
                Icon={<BubbleChatIcon style={{ width: "40px", height: "40px" }} />}
                w="full"
                css={{
                  animation: inView ? `${scaleUpThenShrink} 3s ease-in-out` : "none",
                }}
                whiteSpace={"wrap"}
              />
            </Box>
          </Flex>
          <Flex flexGrow={1} w="full">
            <Box w="full" mx={"1rem"}>
              <Card
                title="Simple Integration"
                body="Easily add Flash Interviews to your existing job postings."
                borderRadius={"0rem 1rem 1rem 1rem"}
                mt={["1rem"]}
                Icon={<Img src="images/intergrate.png" width="40px" height="40px" />}
                w="full"
                css={{
                  animation: inView ? `${scaleUpThenShrink} 3s ease-in-out` : "none",
                }}
              />
            </Box>
          </Flex>
          <Flex flexGrow={1} w="full">
            <Box w="full" mx={"1rem"}>
              <ImageCard
                title="Efficient Management"
                body="An admin side for recruiters  to manage applicants and  <br/> data effortlessly."
                borderRadius={"0rem 1rem 1rem 1rem"}
                mt={"1rem"}
                w="full"
                css={{
                  animation: inView ? `${scaleUpThenShrink} 3s ease-in-out` : "none",
                }}
                whiteSpace={"nowrap"}
                // p={"1rem 1rem 1.5rem 1rem"}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
