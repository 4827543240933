import { Flex } from "@chakra-ui/react";

type props = { isOpen: boolean; onClick?: () => void; removeTop?: boolean; Zindex?: number; opacity?: number };
export const Overlay = ({ isOpen, onClick, removeTop, Zindex, opacity }: props) => {
  return (
    <Flex
      pos="fixed"
      top={removeTop ? "" : 0}
      left={0}
      width={"100vw"}
      height={"100vh"}
      bgColor={`rgba(0, 0, 0, ${opacity || 0.6})`}
      zIndex={Zindex || 10}
      display={isOpen ? "block" : "none"}
      cursor={"pointer"}
      onClick={onClick}
    />
  );
};
