import { Box, Flex, Text } from "@chakra-ui/react";
import { BlueLightningIcon, TimerIcon } from "../../icons";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useRef } from "react";

export const ProblemSectionOne = () => {
  const lineRef = useRef<HTMLSpanElement>(null);
  const line2Ref = useRef<HTMLSpanElement>(null);
  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: { trigger: lineRef.current, start: "top bottom", end: "bottom top", toggleActions: "restart none none none" },
      });
      tl.fromTo(lineRef.current, { width: "0" }, { width: "100%", duration: 1, ease: "power2.in" }).fromTo(
        line2Ref.current,
        { width: "0" },
        { width: "100%", duration: 1, ease: "power2.in" }
      );
    },
    { scope: lineRef }
  );

  return (
    <Box
      w="full"
      className="section-1"
      h={["55vh", "55vh", "55vh", "100vh", "100vh"]}
      overflowX={"hidden"}
      bgColor={"#fff"}
      pos="relative"
      zIndex={10}
    >
      <Flex
        w="full"
        h="full"
        align={"center"}
        flexDir={"column"}
        justify={"center"}
        p={["5rem 2rem 0rem 1rem", "5rem 2rem 0rem 1rem", "0rem 5rem 0rem 5rem"]}
      >
        <Flex align={"center"} flexDir={"column"} rowGap={["1.5rem", "1.5rem", "2rem", "2.5rem", "2.8rem", "3rem", "3.5rem"]}>
          <Flex align={"center"} columnGap={"1rem"}>
            <BlueLightningIcon />
            <TimerIcon />
          </Flex>
          <Flex rowGap={[".5rem", "1rem", "1.4rem"]} flexDir={"column"}>
            <Text
              fontWeight={"800"}
              fontSize={["2rem", "2.5rem", "2.8rem", "2.2rem", "2.5rem", "3rem", "3.5rem"]}
              textAlign={"center"}
              color={"#343231"}
              lineHeight={["39.01px", "44px", "56px"]}
              whiteSpace={["pre-wrap", "pre-wrap", "wrap", "nowrap"]}
            >
              Increase your hiring
              {"  "}
              speed{" "}
              <Box as="span" pos="relative">
                <Box as="span" pos="relative" zIndex={10}>
                  from days to{" "}
                  <Box as="span" display={["none", "none", "none", "inline-block"]}>
                    {" "}
                    minutes
                  </Box>
                </Box>
                <Box
                  ref={lineRef}
                  as="span"
                  pos={"absolute"}
                  bottom={[".6rem", ".6rem", ".6rem", ".6rem", ".8rem", "1rem"]}
                  left={0}
                  w="0"
                  h={[".6rem", ".6rem", ".6rem", ".6rem", ".8rem", "1rem"]}
                  bgColor={"#CBF356"}
                />
              </Box>
              <Box as="span" pos="relative" display={["inline-block", "inline-block", "inline-block", "none"]}>
                <Box as="span" pos="relative" zIndex={10}>
                  <Box as="span" pos="relative" zIndex={10} display={["none", "none", "none", "inline-block", "inline-block"]}>
                    {"\u00A0"}
                  </Box>
                  minutes
                </Box>

                <Box
                  ref={line2Ref}
                  w="0"
                  as="span"
                  pos={"absolute"}
                  bottom={[".6rem", ".6rem", ".6rem", ".6rem", ".8rem", "1rem"]}
                  left={0}
                  h={[".6rem", ".6rem", ".6rem", ".6rem", ".8rem", "1rem"]}
                  bgColor={"#CBF356"}
                />
              </Box>
            </Text>

            <Text
              fontSize={["1rem", "1.2rem", "1.2rem", "1.4rem", "1.6rem", "1.8rem", "2rem"]}
              lineHeight={["19.5px", "30px", "39px"]}
              color={"#343231"}
              textAlign={"center"}
              p={[".5rem", ".75rem", "1.5rem", "2rem"]}
              fontWeight={"400"}
              whiteSpace={["nowrap", "nowrap", "wrap", "nowrap", "nowrap"]}
            >
              With the current job market dynamics,
              <Box as="br" display={["inline-block", "inline-block", "none", "none"]} /> traditional hiring processes are becoming{" "}
              <Box as="br" display={["inline-block", "inline-block", "none", "none"]} /> less{" "}
              <Box as="br" display={["none", "none", "none", "inline-block"]} /> effective, leading to increased no-show{" "}
              <Box as="br" display={["inline-block", "inline-block", "none", "none"]} /> rates.{" "}
              <Box as="span" fontWeight={"bold"}>
                Candidates' expectations have <Box as="br" /> evolved;
              </Box>
              they seek clarity, engagement, and <Box as="br" display={["inline-block", "inline-block", "none", "none"]} /> value from the recruitment
              process.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
