import { Box, Flex, Img } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { ChatIcon, HeadSetIcon, LightningIcon, PLusIcon } from "../../icons";
import { clickHandler } from "../../utils";
import { CustomButton } from "../button";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
export const Hero = () => {
  const container = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      const tl = gsap.timeline({ defaults: { ease: "sine.in" } });
      tl.fromTo(
        [".hero-title p:nth-child(1)", ".hero-title p:nth-child(2)"],
        { opacity: 0, y: "+2.5" },
        { opacity: 1, y: 0, duration: 0.5, delay: 0.8, stagger: 0.05, ease: "power1.out" }
      );
      tl.fromTo(".hero-text", { opacity: 0, x: "+2.5" }, { opacity: 1, x: 0, duration: 0.5, stagger: 0.05 }, "-=0.5");
      tl.fromTo(
        [".discover-btn", ".get-started-btn", ".flash-interview-btn"],
        { scaleX: 0, transformOrigin: "left center" },
        { scaleX: 1, duration: 0.5 },
        "-=1.1"
      );

      return () => {
        tl.kill();
      };
    },
    { scope: container }
  );

  return (
    <Box id="home" className="panel" w="full" h="100vh" background="linear-gradient(104.35deg, #BE82FF 0%, #7E14F7 49.25%)" overflow={"hidden"}>
      <Box w="full" h="full" ref={container} px={["0rem", "0rem", "0rem", "2rem", "2rem", "2rem"]} pos="relative">
        <Box
          display={["none", "none", "none", "inline-block", "inline-block", "inline-block"]}
          pos="absolute"
          top={0}
          zIndex={1}
          left="60%"
          transform={"translateX(-40%)"}
        >
          <Img src="svg/hero/shape-green.svg" w="full" />
        </Box>

        <Flex pos="absolute" justify={"flex-end"} bottom={"0"} right={"0rem"}>
          <Img src="svg/hero/shape-purple.svg" w={["50%", "60%", "70%", "full"]} />
        </Flex>
        <Box display={["none", "none", "none", "inline-block"]} pos="absolute" bottom={"0"} right={"0rem"}>
          <Img src="svg/hero/line.svg" h="full" w="full" />
        </Box>
        <Flex
          w="full"
          h="full"
          pt={["6rem", "6rem", "6rem", "1rem", "1rem", "1rem"]}
          align={"center"}
          flexDir={["column-reverse", "column-reverse", "column-reverse", "row", "row"]}
          justify={"space-between"}
        >
          <Box w="full" flexGrow={1} pos="relative" zIndex={10} px={["1rem", "1rem", "1rem", "0rem", "0rem", "0rem"]}>
            <CustomButton
              title="Flash Interviews"
              handleSubmit={() => {}}
              bgColor={"#B176FE !important"}
              color={"#fff !important"}
              py={[".75rem !important", "1rem", "1.5rem"]}
              px={["1rem !important", "1rem", "1.5rem"]}
              borderRadius={"1.25rem 1.25rem 1.25rem .125rem !important"}
              pos={["absolute", "absolute", "absolute", "static"]}
              top={["-2.5rem", "-2.5rem", "-2.5rem", "unset", "unset"]}
              fontSize={[".875rem !important", "1rem !important", "1rem !important"]}
              className="flash-interview-btn"
            />
            <Box
              fontSize={["3.25rem", "3.25rem", "3.5rem", "4rem", "4.5rem", "5.5rem"]}
              fontWeight={"800"}
              lineHeight={["52px", "52px", "80px", "90px"]}
              color={"#fff"}
              my={"1rem"}
              pos="relative"
              zIndex={50}
              className="hero-title"
            >
              <Box as="p" whiteSpace={"nowrap"}>
                Say goodbye
              </Box>
              <Box as="p" whiteSpace={"nowrap"}>
                to{" "}
                <Box as="span" pos="relative">
                  <Box as="span" pos="relative" zIndex={10}>
                    no-shows
                  </Box>
                  <Box as="span" pos={"absolute"} bottom={["1rem"]} left={0} w="full" h={["1rem"]} bgColor={"#FE67D7"} />
                </Box>
              </Box>
            </Box>
            <Box
              color={"#fff"}
              as="p"
              fontSize={["1rem", "1rem", "1rem", "1rem", "1rem", "1rem", "1.5rem"]}
              lineHeight={["24px", "24px", "32px"]}
              // whiteSpace={"nowrap"}
              pos={"relative"}
              zIndex={50}
              className="hero-text"
            >
              Designed for dynamic industries requiring rapid staffing,
              <Box as="br" display={["none", "none", "none", "inline-block", "inline-block"]} />
              Flash connects job seekers with hiring professionals <Box as="br" display={["none", "none", "none", "inline-block", "inline-block"]} />
              instantly, making the hiring process quick and efficient.
            </Box>
            <Flex align={"center"} justify={"flex-start"} columnGap={"1rem"} mt={["2rem"]}>
              <CustomButton
                title="Discover how"
                handleSubmit={() => clickHandler("solution")}
                bgColor={"transparent !important"}
                color={"#CBF356 !important"}
                m={"0rem !important"}
                py={".75rem"}
                px={"1.5rem"}
                border={"1px solid #CBF356"}
                borderRadius={"20px 20px 20px 2px !important"}
                _hover={{ backgroundColor: "#343231 !important", color: "#CBF356 !important" }}
                className="discover-btn"
              />
              <CustomButton
                handleSubmit={() => clickHandler("contact")}
                title="Get started"
                btnTypes="priMd"
                m={"0rem !important"}
                svg="svg/pricing/arrow-right.svg"
                _hover={{ backgroundColor: "#343231", color: "#CBF356" }}
                className="get-started-btn"
              />
            </Flex>
          </Box>
          <ImagesAnimation />
        </Flex>
      </Box>
    </Box>
  );
};

const ImagesAnimation = () => {
  return (
    <Flex
      flexGrow={["unset", "unset", "unset", 1, 1, 1, 1]}
      w="full"
      justify={["flex-start", "flex-start", "flex-start", "space-evenly", "space-evenly"]}
      align={"center"}
      columnGap={["0rem", "1.5rem", "2rem", "2.5rem"]}
      pos="relative"
    >
      <ColumnIcons />
      <Flex display={["flex", "flex", "flex", "none", "none", "none"]} pos={"relative"} pb={[4]} w="full">
        <Box
          width={"75%"}
          h="full"
          pos="absolute"
          top={0}
          bottom={0}
          left={0}
          rotate={"-114.63 deg"}
          bgImage={"svg/hero/mobile-shape-green.svg"}
          bgPos={"left"}
          bgSize={"auto"}
          bgRepeat={"no-repeat"}
          // zIndex={10}
        />
        <MobileSlider />
      </Flex>

      <Flex display={["none", "none", "none", "flex", "flex"]} w="full" flexDir={"row"} columnGap={"2rem"}>
        <Animation
          key={"neg"}
          direction="neg"
          images={["images/man-talking.gif", "images/woman-with-headsets.jpg", "images/woman-talking-overheadsets.gif"]}
        />
        <Animation key={"pos"} direction="pos" images={["images/typing.gif", "images/medium-shot-smiley-man-wearing-headphones.png"]} />
      </Flex>
    </Flex>
  );
};

const MobileSlider = () => {
  const firstTextBox = useRef<HTMLDivElement>(null);
  const secondTextBox = useRef<HTMLDivElement>(null);
  const xPercentage = useRef<number>(0);
  const direction = useRef<number>(-1);
  const commonFlexStyles = { bgSize: "cover", bgPos: "center", bgRepeat: "no-repeat" };

  useEffect(() => {
    const animation = () => {
      if (xPercentage.current <= -100) xPercentage.current = 0;

      gsap.set(firstTextBox.current, { xPercent: xPercentage.current });
      gsap.set(secondTextBox.current, { xPercent: xPercentage.current + 100 });
      xPercentage.current += 1 * direction.current;
      requestAnimationFrame(animation);
    };

    const animationId = requestAnimationFrame(animation);

    return () => cancelAnimationFrame(animationId);
  }, []);

  return (
    <Flex w="full" overflow={"hidden"} zIndex={5} pos="relative" align={"center"}>
      <Flex ref={firstTextBox} align={"center"} columnGap={".75rem"} mr={".75rem"}>
        {["images/man-talking.gif", "images/woman-with-headsets.jpg", "images/woman-talking-overheadsets.gif"].map(img => (
          <Flex width={["150px", "150px", "250px"]} height={["35vh"]} {...commonFlexStyles}>
            <Img src={img} w="full" h="full" borderRadius="1rem" objectFit="cover" />
          </Flex>
        ))}
      </Flex>
      <Flex ref={secondTextBox} pos="absolute" align={"center"} columnGap={".75rem"} ml={".75rem"}>
        {["images/man-talking.gif", "images/woman-with-headsets.jpg", "images/woman-talking-overheadsets.gif"].map(img => (
          <Flex w={["150px", "150px", "250px"]} height={["35vh"]} {...commonFlexStyles}>
            <Img src={img} w="full" h="full" borderRadius="1rem" objectFit="cover" />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

const ColumnIcons = () => {
  const container = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      const tl = gsap.timeline({ defaults: { ease: "power1.out" } });

      const ctn = container.current;
      if (ctn) tl.fromTo(ctn.querySelectorAll(".icon"), { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.5, delay: 0.8, stagger: 0.2 });

      return () => {
        tl.kill();
      };
    },
    { scope: container }
  );

  return (
    <Flex ref={container} pos={["absolute", "absolute", "absolute", "static", "static"]} zIndex={10} right={0} pr={"1.5rem"}>
      <Flex display={["none", "none", "none", "flex", "flex"]}>
        <Flex display={["none", "none", "none", "flex", "flex"]} align={"center"} flexDir={"column"} rowGap={[".5rem", ".75rem", "1rem", "1.5rem"]}>
          <LightningIcon className="icon" style={{ width: "56px", height: "56px" }} />
          <HeadSetIcon className="icon" style={{ width: "56px", height: "56px" }} />
          <ChatIcon className="icon" style={{ width: "56px", height: "56px" }} />
          <PLusIcon className="icon" style={{ width: "32px", height: "32px" }} />
        </Flex>
      </Flex>
      <Flex display={["flex", "flex", "flex", "none", "none"]}>
        <Flex display={["flex", "flex", "flex", "none", "none"]} align={"center"} flexDir={"column"} rowGap={[".5rem", ".75rem", "1rem", "1.5rem"]}>
          <LightningIcon className="icon" style={{ width: "48px", height: "48px" }} />
          <HeadSetIcon className="icon" style={{ width: "48px", height: "48px" }} />
          <ChatIcon className="icon" style={{ width: "48px", height: "48px" }} />
          <PLusIcon className="icon" style={{ width: "24px", height: "24px" }} />
        </Flex>
      </Flex>
    </Flex>
  );
};

const Animation = ({ images, direction: dir }: { images: string[]; direction: "neg" | "pos" }) => {
  const firstTextBox = useRef<HTMLDivElement>(null);
  const secondTextBox = useRef<HTMLDivElement>(null);
  const xPercentage = useRef<number>(0);
  const direction = useRef<number>(dir === "neg" ? -1 : 1);
  const containerRef = useRef<HTMLDivElement>(null);
  const animationIdRef = useRef<number | null>(null);
  const animationStarted = useRef<boolean>(false);

  useGSAP(
    () => {
      const tl = gsap.timeline({
        onUpdate: () => {
          const scaleValue = tl.progress();

          if (scaleValue >= 0.8 && !animationStarted.current) {
            animationIdRef.current = requestAnimationFrame(animation);
            animationStarted.current = true;
          }
        },
      });

      tl.fromTo(
        [firstTextBox.current, secondTextBox.current],
        { y: dir === "neg" ? "+100" : "-100", opacity: 0, scale: 0 },
        { y: 0, opacity: 1, scale: 1, duration: 0.8, delay: 0.8, stagger: 0.25, ease: "power1.inOut" }
      );

      const animation = () => {
        if (xPercentage.current <= -100) xPercentage.current = 0;
        if (xPercentage.current >= 0 && dir === "pos") {
          xPercentage.current = -100;
        }
        gsap.set(firstTextBox.current, { yPercent: xPercentage.current });
        gsap.set(secondTextBox.current, { yPercent: xPercentage.current + 100 });
        xPercentage.current += 1 * direction.current;
        animationIdRef.current = requestAnimationFrame(animation);
      };

      return () => {
        if (animationIdRef.current) cancelAnimationFrame(animationIdRef.current);
      };
    },
    { scope: containerRef }
  );

  return (
    <Flex ref={containerRef} w="full" overflow={"hidden"} zIndex={5} pos="relative" align={"center"}>
      <Flex ref={firstTextBox} align={"center"} flexDir={"column"} columnGap={".75rem"}>
        {images.map((img, i) => (
          <Img
            key={i}
            className="box"
            src={img}
            width="full"
            height={
              dir === "pos"
                ? ["388px", "463px", "538px", "613px", "688px", "763px", "813px"]
                : ["242px", "292px", "342px", "392px", "442px", "492px", "542px"]
            }
            zIndex={50}
            borderRadius="2.5rem"
            my={"1rem"}
            objectFit={"cover"}
          />
        ))}
      </Flex>
      <Flex ref={secondTextBox} pos="absolute" flexDir={"column"} align={"center"} columnGap={".75rem"}>
        {images.map((img, i) => (
          <Img
            key={i}
            className="box"
            src={img}
            width="full"
            height={
              dir === "pos"
                ? ["388px", "463px", "538px", "613px", "688px", "763px", "813px"]
                : ["242px", "292px", "342px", "392px", "442px", "492px", "542px"]
            }
            borderRadius="2.5rem"
            my={"1rem"}
            objectFit={"cover"}
          />
        ))}
      </Flex>
    </Flex>
  );
};
