import { Box, BoxProps, Flex, Grid, Text } from "@chakra-ui/react";
import React, { ReactNode, useRef } from "react";
import { CustomButton } from "../button";
import { ArrowRight, BlueGreenLightningIcon, GreenLightningIcon } from "../../icons";
import { clickHandler } from "../../utils";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export const Pricing = () => {
  const commonTitleStyles = {
    my: ["1rem", "1rem", "1.25rem", "1.5rem"],
    py: ["1rem", "1rem", "1.25rem", "1.5rem"],
    fontWeight: "700",
    fontSize: ["1.25rem", "1.25rem", "1rem", "1rem", "1.2rem", "1.5rem"],
    borderTop: "1px solid",
    borderBottom: "1px solid #343231",
    whiteSpace: "nowrap",
  };

  const lineStyles = { bottom: ".1rem", left: 0, w: "full", h: [".4rem", ".6rem", ".8rem"], bgColor: "#CBF356" };

  return (
    <Box id="pricing" className="panel" w="full" minH="100vh" p={["2.5rem 1rem 2.5rem 1rem"]} pos={"relative"} overflow={"hidden"} mt={-2}>
      <Box position="relative" w="full" zIndex={1}>
        <Text
          w="full"
          whiteSpace={"nowrap"}
          fontWeight={"800"}
          fontSize={["6.25rem", "6.25rem", "8rem", "10rem", "11rem", "15rem", "17.5rem"]}
          opacity={0.85}
          color={"#F0F0F0"}
          textAlign={"center"}
          position="absolute"
          top={["-0.75rem", "-0.75rem", "-1rem", "-2rem", "-2.5rem", "-3rem", "-4rem", "-6rem", "-8rem"]}
          zIndex={10}
        >
          Pricing Plans
        </Text>
        <Flex w="full" align={"center"} justify={"center"} pos={"relative"} zIndex={50}>
          <CustomButton
            title="Pricing Plans"
            handleSubmit={() => {}}
            bgColor={"#B176FE !important"}
            color={"#fff !important"}
            py="1.5rem"
            borderRadius={"1.25rem 1.25rem 1.25rem .125rem !important"}
            mt={"4rem"}
          />
        </Flex>
      </Box>
      <Box w="full" mx={[".5rem"]}>
        <Grid
          templateColumns={["1fr", "1fr", "1fr", "repeat(3, 1fr)"]}
          gap={[6, 6, 8, 3, 3]}
          justifyItems="center"
          mt={[".5rem", "0.5rem", "0.5rem", "-1rem"]}
          mb={["1rem", "1rem", "1rem", "2rem"]}
          color="#343231"
          w="full"
        >
          <PricingCard
            category="Free"
            priceTag="$0/mo"
            titleText={
              <Text {...commonTitleStyles}>
                The{" "}
                <Box as="span" pos="relative">
                  <Box as="span" pos="relative" zIndex={10}>
                    quickest and easiest
                  </Box>
                  <Box as="span" position={"absolute"} {...lineStyles} />
                </Box>{" "}
                way <Box as="br" display={["inline-block", "inline-block", "none"]} /> to
                <Box as="br" display={["none", "none", "inline-block"]} /> try Flash Interviews
              </Text>
            }
            bodyTexts={["Access to the Flash Interview platform", "Conduct up to 10 interviews", "Basic analytics and reporting", "Email support"]}
            Icon={
              <>
                <Box display={["none", "none", "inline-block"]}>
                  <GreenLightningIcon style={{ width: "47px", height: "64px" }} />
                </Box>
                <Box display={["inline-block", "inline-block", "none"]}>
                  <GreenLightningIcon style={{ width: "40px", height: "40px" }} />
                </Box>
              </>
            }
          />

          <PricingCard
            category="Professional"
            priceTag="$12.50/mo"
            titleText={
              <Text {...commonTitleStyles}>
                <Box as="span" pos="relative">
                  <Box as="span" pos="relative" zIndex={10}>
                    More power
                  </Box>
                  <Box as="span" position={"absolute"} {...lineStyles} />
                </Box>{" "}
                for growing <br /> companies to streamline their <br />
                hiring process.
              </Text>
            }
            bodyTexts={[
              "Unlimited interviews",
              "Advanced analytics and reporting",
              "Priority email and chat support",
              "Customizable interview templates",
              "Integration with HR software",
              "Access for up to 5 recruiters",
            ]}
            Icon={
              <>
                <Box display={["none", "none", "inline-block"]}>
                  <BlueGreenLightningIcon style={{ width: "55px", height: "72px" }} />
                </Box>
                <Box display={["inline-block", "inline-block", "none"]}>
                  <BlueGreenLightningIcon style={{ width: "40px", height: "40px" }} />
                </Box>
              </>
            }
          />

          <PricingCard
            category="Enterprise"
            priceTag="Custom pricing"
            titleText={
              <Text {...commonTitleStyles}>
                Large organizations with <br />
                <Box as="span" pos="relative">
                  <Box as="span" pos="relative" zIndex={10}>
                    extensive hiring needs
                  </Box>
                  <Box as="span" position={"absolute"} {...lineStyles} />
                </Box>{" "}
                looking <br />
                for a fully customized solution.
              </Text>
            }
            bodyTexts={[
              "All Professional Plan features",
              "Custom number of interviews and job postings",
              "Dedicated account manager",
              "Onboarding and training sessions for recruiters",
              "Custom integration with enterprise systems",
              "Enhanced security features",
            ]}
            Icon={
              <>
                <Box display={["none", "none", "inline-block"]}>
                  <BlueGreenLightningIcon style={{ width: "55px", height: "72px" }} />
                </Box>
                <Box display={["inline-block", "inline-block", "none"]}>
                  <BlueGreenLightningIcon style={{ width: "40px", height: "40px" }} />
                </Box>
              </>
            }
            mb={["1.5rem", ".5rem", "1rem", "1.5rem"]}
          />
        </Grid>
      </Box>
    </Box>
  );
};

interface PricingCardProps extends BoxProps {
  category: string;
  priceTag: string;
  titleText: ReactNode;
  bodyTexts: string[];
  Icon: ReactNode;
}
const PricingCard = ({ category, priceTag, titleText, bodyTexts, Icon, ...props }: PricingCardProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const mainCardRef = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      const cardEl = cardRef.current;
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: mainCardRef.current,
          start: "top bottom",
          end: "bottom top",
          toggleActions: "restart none none none",
        },
      });
      tl.fromTo(cardEl, { opacity: 0.0, y: +100 }, { opacity: 1, y: 0, duration: 1, ease: "power1.in" });
      return () => {
        tl.kill();
      };
    },
    { scope: mainCardRef }
  );

  return (
    <Box
      ref={mainCardRef}
      w={"full"}
      h="full"
      mr={"1rem"}
      bgColor={"#FBFBFB"}
      boxShadow={"0px 0px 50px 0px #0000000D"}
      borderRadius={".125rem 2.5rem 2.5rem 2.5rem"}
      p={["2.5rem .5rem", "2rem 1rem", "2rem 1rem", "2rem 1rem", "2rem 1rem", "2.5rem 1rem", "3rem 1.5rem", "4.5rem", "5rem"]}
      mt={["1.5rem", "0rem", "0rem", "2rem"]}
      pos={"relative"}
      zIndex={10}
      {...props}
    >
      <Box ref={cardRef} pos={"relative"} w="full" h={"full"} my={[".5rem", ".5rem", "1rem", "1.5rem"]}>
        <Flex justify={"space-between"}>
          <Box>
            <Text fontWeight={"800"} fontSize={["2rem", "2.5rem", "3rem"]} lineHeight={["39.01px", "39.01px", "48.01px", "58px"]}>
              {category}
            </Text>
            <Text fontWeight={"400"} fontSize={["1.5rem", "1.5rem", "2rem", "2rem"]} whiteSpace={"nowrap"}>
              {priceTag}
            </Text>
          </Box>

          {Icon}
        </Flex>

        {titleText}

        <Flex flexDir={"column"} rowGap={["1rem", "1rem", "1.25rem", "1.5rem"]}>
          {bodyTexts.map(txt => (
            <Flex align={"center"}>
              <Box display={["none", "none", "inline-block", "inline-block"]} w={"40px"} h={"40px"}>
                <ArrowRight style={{ width: "40px", height: "40px" }} />
              </Box>
              <Box display={["inline-block", "inline-block", "none", "none"]} w={"20px"} h={"20px"}>
                <ArrowRight style={{ width: "20px", height: "20px" }} />
              </Box>
              <Text
                whiteSpace={"no-wrap"}
                fontWeight={"400"}
                fontSize={["1rem", "1rem", "1.25rem", "1.25rem"]}
                ml={".5rem"}
                lineHeight={["19.5px", "19.5px", "22.5px", "24.38px"]}
              >
                {txt}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Box pos={"absolute"} bottom={["-3rem", "-3rem", "-2rem", "-2rem", "-2rem"]} left={"50%"} transform="translateX(-50%)">
          <CustomButton
            handleSubmit={() => clickHandler("contact")}
            title="Get started"
            btnTypes="priMd"
            m={"0rem !important"}
            svg="svg/pricing/arrow-right.svg"
            _hover={{ backgroundColor: "#343231", color: "#CBF356" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
