import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { BlueGreenLightningIcon, HomeIcon } from "../../icons";
import { clickHandler } from "../../utils";
import { CustomButton } from "../button";
import { useGSAP } from "@gsap/react";

export interface HeaderProps {
  color: string;
}

export const Header = ({ color }: HeaderProps) => {
  useGSAP(() => {
    const tl = gsap.timeline({ defaults: { ease: "sine.in" } });

    tl.fromTo([".logo", ".try-out"], { scaleX: 0, transformOrigin: "left center" }, { scaleX: 1, duration: 0.5, delay: 0.5 }).fromTo(
      ".nav",
      { scale: 0 },
      { scale: 1, duration: 0.5 },
      "-=0.3"
    );

    return () => {
      tl.kill();
    };
  }, {});
  return (
    <Flex id="header" w="full" pos="relative" display={["none", "none", "none", "flex", "flex"]}>
      <Flex
        w="full"
        justify={"space-between"}
        align={"center"}
        py={["2rem", "2rem", "2rem", "3rem"]}
        px={["1rem", "1.25rem", "1.5rem", "2rem"]}
        zIndex={50}
        pos={"fixed"}
      >
        <Flex className="logo" align={"center"} columnGap={".5rem"}>
          <BlueGreenLightningIcon style={{ width: "40px", height: "40px" }} />
          <Text fontSize={"2.5rem"} fontWeight={"800"} color={color}>
            flash
          </Text>
        </Flex>
        <Flex
          align={"center"}
          borderEndRadius={"1.5rem"}
          borderTopLeftRadius={"1.5rem"}
          justify={"space-between"}
          bgColor={"#F0F0F0"}
          color={"#000"}
          fontWeight={"600"}
          p={[".5rem .8rem", ".5rem 1rem", ".5rem 1.2rem", ".5rem 1.4rem", ".5rem 1.4rem"]}
          className="nav"
        >
          <HomeIcon onClick={() => clickHandler("home")} />
          {["The solution", "How it works", "Benefits", "Pricing"].map((txt, i) => (
            <Item i={i} txt={txt} />
          ))}
        </Flex>
        <CustomButton
          handleSubmit={() => clickHandler("contact")}
          title="Try out"
          btnTypes="priMd"
          m={"0rem !important"}
          svg="svg/hero/arrow-right_circle.svg"
          className="try-out"
        />
      </Flex>
    </Flex>
  );
};

interface ItemProps extends BoxProps {
  txt: string;
  i: number;
}

export const Item = ({ i, txt }: ItemProps) => {
  const rectangleRef = useRef(null);

  useEffect(() => {
    gsap.to(rectangleRef.current, { width: 0, duration: 0.5, ease: "power2.out" });
  });

  const handleHover = () => {
    gsap.to(rectangleRef.current, { width: "100%", duration: 0.5, ease: "power2.out" });
  };

  const handleMouseLeave = () => {
    gsap.to(rectangleRef.current, { width: 0, duration: 0.5, ease: "power2.out" });
  };
  return (
    <Box
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      key={i}
      as="a"
      px={[".4rem", ".4rem", ".5rem", ".75rem"]}
      onClick={() => clickHandler(txt)}
    >
      <Box as="span" pos="relative">
        <Box as="span" pos="relative" zIndex={10}>
          {txt}
        </Box>
        <Box
          ref={rectangleRef}
          as="span"
          pos={"absolute"}
          bottom={0}
          left={0}
          w="full"
          h={[".25rem", ".25rem", ".25rem", ".5rem"]}
          bgColor={"#CBF356"}
        />
      </Box>
    </Box>
  );
};
