import { Box, Flex, Text, Img } from "@chakra-ui/react";
import { ArrowRight, DoodleIcon } from "../../icons";
import { ReactNode } from "react";

export const BenefitsSection = () => {
  const TextStyles = {
    fontWeight: "400",
    lineHeight: ["19.5px", "19.5px", "25px", "29.26px"],
    fontSize: ["1rem", "1rem", "1.2rem", "1.5rem"],
    whiteSpace: ["wrap", "wrap", "nowrap"],
  };

  const benefits = [
    {
      title: "Eliminate no-shows",
      body: (
        <Text {...TextStyles}>
          Engage candidates directly, ensuring they're
          <Box as="br" display={["none", "none", "inline-block"]} />
          invested.
        </Text>
      ),
    },
    {
      title: "Reduce hiring time",
      body: (
        <Text {...TextStyles}>
          Fill positions faster with instant interview <Box as="br" display={["none", "none", "inline-block"]} /> connections.
        </Text>
      ),
    },
    {
      title: "Seamless integration",
      body: (
        <Text {...TextStyles}>
          Easily add links to your existing platforms and <Box as="br" display={["none", "none", "inline-block"]} /> effortlessly export data.
        </Text>
      ),
    },
  ];

  return (
    <Box id="benefits" className="panel" w="full" minH="100vh" background="#FAFAFA" opacity={1} position="relative">
      <Box
        position="absolute"
        bgImage={"url(images/benefits-bg.png)"}
        bgSize={"contain"}
        bgPos={["top", "top", "top", "left", "left"]}
        bgRepeat={"no-repeat"}
        top={["-2rem", "-2rem", "-2rem", "-3rem"]}
        left={0}
        w={["50%", "40%", "40%", "90%", "full"]}
        h={["full", "full", "full", "90%", "80%"]}
        zIndex={1}
      />

      <Flex
        flexDir={["column", "column", "column", "row", "row"]}
        align={"center"}
        p={["5rem 1rem 1rem 1rem", "5rem 1rem 5rem 1rem", "3.2rem", "3.5rem", "4rem", "4.5rem", "5rem"]}
        w="full"
        h="full"
        columnGap={"1.5rem"}
        overflowX={"hidden"}
      >
        <Flex
          bgColor={"#E2E1E11A"}
          boxShadow={"0px 0px 50px 0px #0000000D"}
          borderRadius={[".125rem 2rem 2rem 2rem", ".125rem 2rem 2rem 2rem", ".125rem 5rem 5rem 5rem", ".125rem 5rem 5rem 5rem"]}
          p={["2.5rem 0rem 2.5rem 1rem", "2.8rem", "3.2rem", "3.5rem", "4rem", "5rem", "6.5rem", "7.5rem"]}
          pos={"relative"}
          zIndex={10}
          color={"#343231"}
          flexGrow={1}
          h="full"
          mb={[".5rem", "1rem", "1.5rem"]}
        >
          <Box>
            <Text mb={"3rem"} fontWeight={"800"} lineHeight={["39.01px", "39.01px", "56px", "68px"]} fontSize={["2rem", "2rem", "2.75rem", "3.5rem"]}>
              Why choose Flash Interviews?
            </Text>
            <Box width={["full", "full", "full", "90%", "80%"]}>
              {benefits.map((data, i) => (
                <BenefitsRow key={i} body={data.body} title={data.title} />
              ))}
            </Box>
          </Box>
        </Flex>
        <Flex
          pos={"relative"}
          borderRadius={["2rem 2rem .125rem 2rem", "", "5rem 5rem .125rem 5rem"]}
          w={["full", "full", "full", "full", "394px", "394px", "394px"]}
          h={["358px", "466px", "802px"]}
          bgColor={"#CBF356"}
        >
          <Img
            src="images/woman-wearing-casual-with-headset.jpg"
            borderRadius={["2rem 2rem .125rem 2rem", "2rem 2rem .125rem 2rem", "2rem 2rem .125rem 2rem", "5rem 5rem .125rem 5rem"]}
            w="100%"
            h={"100%"}
            objectFit="cover"
          />
          <Box pos={"absolute"} top={"-1.0rem"} right={"-2.5rem"}>
            <DoodleIcon />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

interface BenefitsRowProps {
  title: string;
  body: ReactNode;
}

const BenefitsRow = ({ title, body }: BenefitsRowProps) => {
  return (
    <Flex borderTop={"1px solid #343231"} py={"1.5rem"}>
      <Box display={["none", "none", "none", "inline-block", "inline-block"]} w="40px" h="40px">
        <ArrowRight style={{ width: "40px", height: "40px" }} />
      </Box>
      <Box display={["inline-block", "inline-block", "inline-block", "none", "none"]} w="32px" h="32px">
        <ArrowRight style={{ width: "32px", height: "32px" }} />
      </Box>
      <Box pl={"1.5rem"}>
        <Text fontWeight={"800"} fontSize={["1.25rem", "1.25rem", "1.5rem", "1.75rem"]} lineHeight={["32px", "32px", "40px", "40px"]}>
          <Box as="span" pos="relative">
            <Box as="span" pos="relative" zIndex={10}>
              {title}
            </Box>
            <Box as="span" pos={"absolute"} bottom={[".5rem"]} left={0} w="full" h={[".5rem", ".6rem", ".8rem"]} bgColor={"#CBF356"} />
          </Box>
        </Text>
        {body}
      </Box>
    </Flex>
  );
};
