import { Box, Flex, Text } from "@chakra-ui/react";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { DeepBlueLightningIcon } from "../../icons";

export const TextSlider = () => {
  const firstTextBox = useRef<HTMLDivElement>(null);
  const secondTextBox = useRef<HTMLDivElement>(null);
  const xPercentage = useRef<number>(0);
  const direction = useRef<number>(-1);

  useEffect(() => {
    const animation = () => {
      if (xPercentage.current <= -100) xPercentage.current = 0;

      gsap.set(firstTextBox.current, { xPercent: xPercentage.current });
      gsap.set(secondTextBox.current, { xPercent: xPercentage.current + 100 });
      xPercentage.current += 0.2 * direction.current;
      requestAnimationFrame(animation);
    };

    const animationId = requestAnimationFrame(animation);

    return () => cancelAnimationFrame(animationId);
  }, []);

  return (
    <Flex w="full" bgColor={"#CBF356"} overflow={"hidden"} py={[".125rem", ".125rem", ".5rem"]} pos="relative" columnGap={"1rem"} align={"center"}>
      <Flex ref={firstTextBox} align={"center"} columnGap={"1rem"}>
        {["EFFICIENT", "SIMPLE", "INNOVATIVE", "QUICK"].map((txt, i) => (
          <>
            <Text
              key={i}
              fontWeight={"800"}
              lineHeight={["44px", "44px", "78px", "107.27px"]}
              fontSize={["2.75rem", "2.75rem", "4.5rem", "5.5rem"]}
              color={"#343231"}
            >
              {txt}
            </Text>
            <Box display={["none", "none", "inline-block"]}>
              <DeepBlueLightningIcon style={{ width: "37px", height: "52px" }} />
            </Box>
            <Box display={["inline-block", "inline-block", "none"]}>
              <DeepBlueLightningIcon style={{ width: "23.22px", height: "32px" }} />
            </Box>
          </>
        ))}
      </Flex>
      <Flex ref={secondTextBox} pos="absolute" align={"center"} columnGap={"1rem"}>
        {["EFFICIENT", "SIMPLE", "INNOVATIVE", "QUICK"].map((txt, i) => (
          <>
            <Text
              key={i + 4}
              fontWeight={"800"}
              lineHeight={["53.64px", "53.64px", "78px", "107.27px"]}
              fontSize={["2.75rem", "2.75rem", "4.5rem", "5.5rem"]}
              color={"#343231"}
            >
              {txt}
            </Text>
            <Box display={["none", "none", "inline-block"]}>
              <DeepBlueLightningIcon style={{ width: "37px", height: "52px" }} />
            </Box>
            <Box display={["inline-block", "inline-block", "none"]}>
              <DeepBlueLightningIcon style={{ width: "23.22px", height: "32px" }} />
            </Box>
          </>
        ))}
      </Flex>
    </Flex>
  );
};
