import { Box, Flex, Img, Text } from "@chakra-ui/react";
import { GreenLightningIcon, LogoIcon } from "../../icons";
import { clickHandler } from "../../utils";
import { ContactUsForm } from "../ui/contact-us";
import { CustomButton } from "../button";

export const ContactUs = () => {
  return (
    <Flex id="contact" className="panel" pt={["2.5rem"]} w={"full"} bgColor={["#FAFAFA", "#FAFAFA", "#f7f7f7"]} mr=".5rem" direction={"column"}>
      <Flex
        mt={0}
        pr={["1rem", "1rem", "1rem", 0]}
        w="full"
        flexGrow={1}
        flexDir={["column", "column", "column", "row", "row"]}
        align={"center"}
        justify={"center"}
      >
        <Flex
          w={"full"}
          h={["auto", "auto", "auto", "468px"]}
          align={"center"}
          justify={"center"}
          columnGap={"2.5rem"}
          background="linear-gradient(104.35deg, #BE82FF 0%, #7E14F7 49.25%)"
          borderRadius={["0rem 2rem 0rem 0rem", "0rem 2rem 0rem 0rem", "0rem 2rem 0rem 0rem", "5rem 5rem 0rem 0rem"]}
          pos={"relative"}
          overflow={["auto", "auto", "auto", "hidden"]}
          flexDir={["column", "column", "column", "row"]}
          rowGap={"2.5rem"}
        >
          <Flex
            w="full"
            justify={"center"}
            align={["flex-start", "flex-start", "flex-start", "flex-end"]}
            p={["7.5rem 1rem 0rem 1rem", "7.5rem 1rem 0rem 1rem", "7.5rem 1rem 0rem 1rem", "7.5rem 1rem 7.5rem 5rem"]}
            flexDir={"column"}
            rowGap={"1.5rem"}
          >
            <Flex rowGap={"1rem"} flexDir={"column"}>
              <Text
                whiteSpace={["nowrap"]}
                fontWeight={"800"}
                fontSize={["2rem", "2rem", "3rem", "3.5rem"]}
                lineHeight={["39.01px", "39.01px", "68.26px"]}
                color={"#FFFFFF"}
              >
                Ready to stop <Box as="br" display={["inline-block", "inline-block", "inline-block", "none"]} /> getting{" "}
                <Box as="br" display={["none", "none", "none", "inline-block"]} />
                <Box as="span" pos="relative">
                  <Box as="span" pos="relative" zIndex={10}>
                    stood-up
                  </Box>
                  <Box as="span" pos={"absolute"} bottom={[".5rem"]} left={0} w="full" h={[".5rem", ".5rem", ".8rem"]} bgColor={"#FE67D7"} />
                </Box>
                ?
              </Text>
              <Text color={"#fff"} fontWeight={"400"} fontSize={["1rem", "1rem", "1.75rem", "1.75rem"]} lineHeight={["19.5px", "19.5px", "34.13px"]}>
                Discover how seamless recruiting can be <Box as="br" />
                and revolutionize your process.
              </Text>
            </Flex>
          </Flex>
          <Flex w="full" h="full" align={"center"} justify={"flex-start"} flexDir={["column", "column", "column", "row"]} rowGap={"2.5rem"}>
            <Box w="100%" px={["1rem", "1rem", "1rem", 0]}>
              <Flex
                h={"auto"}
                w={["full", "full", "auto"]}
                flexDir={"column"}
                p={["1.25rem 1rem 1.25rem 1rem", "1.25rem 1rem 1.25rem 1rem", "1.25rem 1rem 1.25rem 1rem", "1.25rem", "1.25rem", "2.5rem"]}
                bgColor={"#B176FE"}
                boxShadow={"0px 0px 50px 0px #0000000D"}
                borderRadius={[".125rem 1rem 1rem 1rem", ".125rem 1rem 1rem 1rem", ".125rem 1rem 1rem 1rem", ".125rem 2.5rem 2.5rem 2.5rem"]}
                rowGap={"1rem"}
              >
                <Flex columnGap={".5rem"} align={"center"}>
                  <GreenLightningIcon style={{ width: "26.5px", height: "36.37px" }} />
                  <Text fontSize={["1.25rem", "1.25rem", "1.25rem", "1.25rem", "1.25rem", "1.75rem"]} lineHeight={["24.38px", "24.38px", "34.13px"]}>
                    Contact us at
                  </Text>
                </Flex>
                <CustomButton
                  handleSubmit={() => (window.location.href = `mailto:sales@flashinterviews.com`)}
                  title="sales@flashinterviews.com"
                  btnTypes="priMd"
                  m={"0rem !important"}
                  svg="svg/pricing/arrow-right.svg"
                  borderRadius={".125rem 1.25rem 1.25rem 1.25rem !important"}
                  borderWidth={"2px !important"}
                  p={".75rem 1.5rem .75rem 1.5rem !important"}
                  lineHeight={["19.5px !important", "19.5px !important", "34.13px !important"]}
                  fontWeight="600 !important"
                  fontSize={["1rem !important", "1rem !important", "1rem !important", "1rem !important", "1.5rem !important", "1.75rem !important"]}
                />
              </Flex>
            </Box>
            <Flex w="full" h="full" pos={"relative"}>
              <Flex pos={["unset", "unset", "unset", "absolute"]} left={"-9rem"} right={0} bottom={0} top={"25%"} zIndex={1}>
                <Img
                  display={["none", "none", "none", "inline-block"]}
                  src="images/pink-shape.png"
                  minW={["400px", "400px", "400px", "400px", "400px", "400px", "550px"]}
                  w="full"
                  h="full"
                />
                <Img
                  display={["inline-block", "inline-block", "inline-block", "none"]}
                  src="images/pink-shape-small.png"
                  minW={["221.14px", "221.14px"]}
                  w="full"
                  h="full"
                />
              </Flex>
              <Flex pos={["unset", "unset", "unset", "absolute"]} top={"4rem"} right={"-4rem"} zIndex={10}>
                <Img src="svg/contact/yellow-vector.svg" ml={["-2rem"]} w="full" h={["140.65px", "140.65px", "auto"]} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {/* <ContactUsForm /> */}
      </Flex>

      <Flex
        w="full"
        align={"center"}
        p={["2.5rem 1rem 2.5rem 1rem", "2.5rem 1rem 2.5rem 1rem", "1.5rem 3.5rem 1.5rem 3.5rem"]}
        bgColor={"#343231"}
        fontSize={"1rem"}
        fontWeight={"400"}
        lineHeight={"19.5px"}
        color={"#fff"}
        direction={["column-reverse", "column-reverse", "column", "column"]}
        justify={["space-between", "space-between", "space-between", "space-between"]}
      >
        <Text
          align={["center", "center", "center", "center"]}
          mt={["1.5rem", "2.5rem", "3rem", "3rem"]}
          order={[1, 1, 2, 2]}
          top={["0", "0", "-20px", "-70px"]}
          position={["static", "static", "relative", "relative"]}
          lineHeight={"14.63px"}
          fontSize={".75rem"}
          fontWeight={"400"}
          textColor={"#fff"}
          display={["inline-block", "inline-block", "none"]}
        >
          © Flash - {new Date(Date.now()).getFullYear()} - All rights reserved.
        </Text>
        <Flex w={["full"]} align={"center"} order={[2, 2, 1, 1]} direction={"row"} justify={"space-between"}>
          <LogoIcon width={"5.5rem"} />
          <Text
            align={["center", "center", "center", "center"]}
            position={["static", "static", "relative", "relative"]}
            lineHeight={"14.63px"}
            fontSize={".75rem"}
            fontWeight={"400"}
            textColor={"#fff"}
            display={["none", "none", "inline-block"]}
          >
            © Flash - {new Date(Date.now()).getFullYear()} - All rights reserved.
          </Text>
          <Flex columnGap={["1rem", "1rem", "2.5rem"]}>
            <Text textDecor={"underline"} onClick={() => clickHandler("pricing")}>
              Pricing
            </Text>
            <Text textDecor={"underline"} onClick={() => clickHandler("contact")}>
              Contact
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
